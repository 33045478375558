@use '../../styles/mixins' as mixins;
@use '../../styles/breakpoints' as bp;
	
.disclaimers-block {

	@include mixins.sectionStyles;

    .disclaimer-content {
        display: grid;
        grid-template-columns: 25% 1fr;
        gap: var(--wp--preset--spacing--60);

        @media only screen and (max-width: bp.$breakpoint-medium) {
            grid-template-columns: 1fr;
            gap: 0;
        }

        .disclaimer-1 {

            p:first-child {
                margin-top: 0;
            }

        }

        a {
            color: currentColor;
            font-weight: 300;
            text-decoration: underline;
            cursor: pointer;
        
            &:hover {
                text-decoration: none;
            }
        }
    }

    &.contained {

        .bnpp-container {
            padding: var(--wp--preset--spacing--70);
        }

        &.light {

            .bnpp-container {
                background-color: var(--wp--preset--color--bnpp-green-tint-15);
            }

            * {
                color: var(--wp--preset--color--bnpp-black);
            }
                        
        }

        &.horizontal-rule {

            .bnpp-container {
                border-top: none!important;
            }
             
        }

    }

    &.horizontal-rule {

        padding-top: calc(var(--wp--preset--spacing--80) / 2);

        @media only screen and (max-width: bp.$breakpoint-medium) {
            padding-top: 0;
        }

        .bnpp-container {
            border-top: solid 2px #D8D8D8;
            padding: calc(var(--wp--preset--spacing--80) / 2) var(--wp--preset--spacing--60) var(--wp--preset--spacing--80);

            @media only screen and (max-width: bp.$breakpoint-medium) {
                padding: calc(var(--wp--preset--spacing--80) / 2) 0 var(--wp--preset--spacing--80);
            }
        }

        &.dark {
            .bnpp-container {
                border-top: solid 2px var(--wp--preset--color--white);
            }
        }

    }

}